<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/SupplyandDemand' }">行业供求市场</el-breadcrumb-item>
            <el-breadcrumb-item>需求市场</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 需求详情 -->
            <section class="section1">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">需求详情</div>
                    </div>
                    <div class="date" style="color: #666;margin-bottom: 11px;">
                        发布日期：2020-12-01
                        <span style="margin-left: 40px;">截至日期：2020-12-31</span>
                        <span class="fr" style="color: #024A8F;font-weight:600">倒计时：7天</span>
                    </div>
                    <el-progress :stroke-width="16" :percentage="80"></el-progress>
                </div>
            </section>
            <!-- 需求状态 -->
            <section class="section2">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">需求状态 : 待解决</div>
                    </div>
                    <div class="center">
                        <p>
                            <span style="color: #666;"> 需求名称：</span>需要一次性医用手套500吨
                        </p>
                        <p>
                            <span style="color: #666;"> 物资类型：</span>抗议物资
                        </p>
                        <p>
                            <span style="color: #666;"> 规格类型：</span>大中小
                        </p>
                        <p>
                            <span style="color: #666;"> 需求数量：</span>26吨
                        </p>
                        <p>
                            <span style="color: #666;"> 需求价格：</span>面议
                        </p>
                        <p>
                            <span style="color: #666;"> 需求说明：</span>一共需要500吨，满26吨一车就取货
                        </p>
                    </div>
                </div>
            </section>
            <!-- 企业信息 -->
            <section class="section3">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">企业信息</div>
                    </div>
                    <div class="center">
                        <p>
                            <span style="color: #666;"> 企业名称：</span>杭州***有限公司
                        </p>
                        <p>
                            <span style="color: #666;"> 所属区域：</span>浙江省-宁波-**区
                        </p>
                        <p>
                            <span style="color: #666;"> 联系人：</span>姓名+职称
                        </p>
                        <p>
                            <span style="color: #666;"> 联系电话：</span>18012344321
                        </p>
                        <p>
                            <span style="color: #666;"> 公司官网：</span>www.baidu.com
                        </p>
                        <p>
                            <span style="color: #666;"> 公司地址：</span>浙江省宁波市******号
                        </p>
                    </div>
                </div>
            </section>
            <p class="return" style="padding-left: 20px;padding-bottom: 20px;">
                <el-button @click="goback">
                    返回
                </el-button>
            </p>
        </div>
    </div>
</template>
<script>
    // 
    export default {
        name: 'demandDetails',
        data() {
            return {

            };
        },
        methods: {
            goback() {
                this.$router.go(-1)
            },

        },
        mounted() {

        },
        created() {

        },
    };
</script>
<style lang="scss" scoped>
    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

        .concent {
            .section1 {
                min-height: 168px;
            }

            .section2 {
                min-height: 291px;
            }

            .section3 {
                min-height: 291px;
            }
        }
    }

    

    /deep/ .el-progress__text {
        display: none;
    }

    /deep/ .el-progress-bar {
        padding-right: 0;
    }

    /deep/ .el-progress-bar__inner {
        border-radius: 0;
        background-image: linear-gradient(270deg, #024A8F 0%, #255ADA 100%);
    }

    /deep/ .el-progress-bar__outer {
        border-radius: 0;
    }

    .dataOverview .right-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .dataOverview .right-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }

    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 16px;
    }

    .chartView .item-box {
        width: calc((100% - 40px) * 0.33333);
        height: 176px;
        border-radius: 0px 0px 4px 4px;
        background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .chartView .item-box:nth-child(3n + 1) {
        margin-right: 0;
    }

    .chartView .item-box:nth-child(n + 4) {
        margin-bottom: 0;
    }

    .chartView .item-box .item-top {
        border-top: 2px solid #4393f8;
        margin-bottom: 16px;
        position: relative;
    }

    .chartView .item-box .item-top .top-bg {
        height: 0px;
        width: 172px;
        border-top: 40px solid #4393f8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -96px;
    }


    .chartView .center p {
        line-height: 30px;
    }

    .chartView .item-box .item-bottom {
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        margin-top: 22px;
        background: #cee4ff;
        border-radius: 0px 0px 4px 4px;
    }

    .chartView .top .time {
        font-size: 14px;
        color: #666666;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
    }

    .chartView .top .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .chartView .item-box .item-title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }

    .postView .post-box {
        width: calc((100% - 16px) * 0.5);
        padding: 16px 20px 24px;
        box-sizing: border-box;
        background-color: #fff;
        margin-bottom: 36px;
    }

    .postView .post-box .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 15px;
    }

    .postView .post-box .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .postView .post-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .postView .post-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }
</style>